import React from 'react';
import './css/privacypolicy.style.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1 className="privacy-header">Privacy Policy for Ring Match</h1>
      <p className="privacy-effective-date">Effective Date: [09/12/2024]</p>

      <section className="privacy-section">
        <h2 className="privacy-subheader">Introduction</h2>
        <p className="privacy-text">
          At Ring Match, we value your privacy and are committed to protecting your personal data.
          This Privacy Policy outlines how we collect, use, and safeguard your information while providing our services.
        </p>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">1. Information We Collect</h2>
        <p className="privacy-text">
          We collect the following user information:
        </p>
        <ul className="privacy-list">
          <li className="privacy-list-item">Personal Information: Name, username, photo, state and country, age, height, weight, bio, profession, astro sign, and gender.</li>
          <li className="privacy-list-item">Authentication Information: Username and unique user ID (UID) authenticated through Pi Network.</li>
        </ul>
        <p className="privacy-text">
          We do not collect or track IP addresses, cookies, or other automatically collected data.
        </p>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">2. Purpose of Data Collection</h2>
        <p className="privacy-text">
          We collect and use this data for:
        </p>
        <ul className="privacy-list">
          <li className="privacy-list-item">Match recommendations tailored to user preferences.</li>
          <li className="privacy-list-item">Personalization of user experience.</li>
          <li className="privacy-list-item">Analytics to improve our services and optimize functionality.</li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">3. Data Sharing</h2>
        <p className="privacy-text">
          We do not share your personal data with any third parties. All data remains securely stored and accessible only by the user or as outlined in this Privacy Policy.
        </p>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">4. User Control</h2>
        <p className="privacy-text">
          You have full control over your profile:
        </p>
        <ul className="privacy-list">
          <li className="privacy-list-item">
            <strong>Editing:</strong> You can update or modify details such as your name, photo, location, age, height, weight, bio, profession, astro sign, and gender through the profile section in the app. Username changes are not permitted.
          </li>
          <li className="privacy-list-item">
            <strong>Account Deletion:</strong> You can delete your account at any time. This will permanently remove all associated data from our platform.
          </li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">5. Security Measures</h2>
        <p className="privacy-text">
          Your data is stored securely using Google Firestore, a highly secure database solution. Additionally:
        </p>
        <ul className="privacy-list">
          <li className="privacy-list-item">Only authenticated users via Pi Network can access their own data.</li>
          <li className="privacy-list-item">Viewing another user's profile requires mutual friendship between both users.</li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">6. Messaging and Communications</h2>
        <p className="privacy-text">
          Ring Match provides a simple text messaging service available only to premium users. Messaging is limited to users who are mutually connected.
        </p>
        <ul className="privacy-list">
          <li className="privacy-list-item">Messages are stored in encrypted form, and will be stored until the user deletes them.</li>
          <li className="privacy-list-item">Users can delete individual messages or all messages at once through the app's menu.</li>
          <li className="privacy-list-item">We do not ask for or collect contact information such as phone numbers or email addresses. Any personal information shared between users is voluntary.</li>
          <li className="privacy-list-item">Messages are stored in Google Firestore, and we use best practices for encryption, secure transmission, and access control.</li>
          <li className="privacy-list-item">We may share message data with legal authorities if required for investigations. We will not share any user's data unless legally required to do so.</li>
          <li className="privacy-list-item">Users can report inappropriate messages or block other users at any time.</li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">7. Compliance with Data Protection Laws</h2>
        <p className="privacy-text">
          Ring Match complies with principles of the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). This includes:
        </p>
        <ul className="privacy-list">
          <li className="privacy-list-item">Obtaining clear and explicit consent before collecting or using personal data.</li>
          <li className="privacy-list-item">Collecting only data necessary for matchmaking services and personalization.</li>
          <li className="privacy-list-item">
            Providing user rights, including access, correction, deletion, and objection to data processing.
          </li>
          <li className="privacy-list-item">Maintaining transparency in how data is collected, used, stored, and shared.</li>
          <li className="privacy-list-item">Ensuring robust data security using Google Firestore.</li>
          <li className="privacy-list-item">
            Notifying relevant authorities and affected users within 72 hours in case of a data breach.
          </li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">8. Payments and Premium Features</h2>
        <p className="privacy-text">
          - Free trial users can add up to five friends.
          - For unlimited friend connections, users must purchase a premium plan using Pi cryptocurrency. We do not accept other forms of payment.
          - This platform exclusively operates on the Pi Browser.
        </p>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">9. Changes to This Privacy Policy</h2>
        <p className="privacy-text">
          We may update this policy from time to time to reflect changes in our services or legal requirements. Users will be notified of any significant updates.
        </p>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subheader">Contact Us</h2>
        <p className="privacy-text">
          If you have any questions about this Privacy Policy, please contact us at [dhaval.j.bhatti@gmail.com].
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
