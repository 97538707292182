import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from react-router-dom
import Footer from '../Footer';
import './css/profile.style.css'; 

function ProfilePage() {
  const navigate = useNavigate(); // Initialize the navigate function
  const username = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user')).username
    : 'Guest'; // Retrieve username from local storage or fallback to 'Guest'

  const handleLogout = () => {
    // Clear session or authentication data here
    localStorage.removeItem('accessToken'); // Remove token from localStorage
    localStorage.removeItem('user'); // Clear user data from localStorage
    navigate('/'); // Navigate to the home page ("/")
  };

  const handleDeleteAccount = () => {
    // Implement account deletion logic here
    alert('Account Deleted'); // Replace with actual deletion logic
  };

  return (
    <div className="opt-profile-page">
      <div className="opt-profile-content">
        {/* Premium Account Section */}
        <div className="opt-premium-status">
          <h3>Account Status</h3>
          <h5>You do not have a Premium account.</h5>
        </div>

        <div className="opt-profile-options">
          <h3>Profile</h3>
          <ul>
            <li onClick={() => navigate('/yourprofile')}>View Profile</li>
            <li onClick={() => navigate('/editprofile')}>Edit Profile</li>
            <li onClick={() => navigate('/friends')}>Friends</li>
          </ul>

          <h3>Account Settings</h3>
          <ul>
            <li onClick={() => navigate('/account/settings')}>Account Settings</li>
            <li onClick={() => navigate('/account/privacy')}>Account Privacy</li>
            <li onClick={() => navigate('/billing')}>Billing</li>
            <li onClick={() => navigate('/blocked')}>Blocked Users</li>
          </ul>

          <h3>Help & Info</h3>
          <ul>
            <li onClick={() => navigate('/developer-team')}>Developer Team</li>
            <li onClick={() => navigate('/help-center')}>Help Center</li>
            <li onClick={() => navigate('/privacypolicy')}>Privacy Policy</li>
            <li onClick={() => navigate('/termsandconditions')}>Terms & Conditions</li>
          </ul>

          <h3>Actions</h3>
          <ul>
            <li onClick={handleLogout} style={{ color: '#FF7FB6', cursor: 'pointer' }}>Logout</li>
            <li onClick={handleDeleteAccount} style={{ color: 'red', cursor: 'pointer' }}>Delete Account</li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ProfilePage;
