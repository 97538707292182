// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import './css/footer.style.css'; // Import the CSS file for footer styles

function Footer() {
    return (
        <footer className="dashboard-footer">
            <div className="footer-icon">
                <Link to="/dashboard">
                    <i className="fas fa-home"></i> {/* Home icon */}
                </Link>
            </div>
            <div className="footer-icon">
                <Link to="/search">
                    <i className="fas fa-search"></i> {/* Search icon */}
                </Link>
            </div>
            <div className="footer-icon">
                <Link to="/add">
                    <i className="fas fa-plus-circle"></i> {/* Add icon */}
                </Link>
            </div>
            <div className="footer-icon">
                <Link to="/notifications">
                    <i className="fas fa-bell"></i> {/* Notification icon */}
                </Link>
            </div>
            <div className="footer-icon">
                <Link to="/profile">
                    <i className="fas fa-user"></i> {/* Profile icon */}
                </Link>
            </div>
        </footer>
    );
}

export default Footer;
