import React from 'react';
import './css/termsandconditions.style.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-header">Terms and Conditions for Ring Match</h1>
      <p className="terms-effective-date">Effective Date: [09/12/2024]</p>

      <section className="terms-section">
        <h2 className="terms-subheader">Introduction</h2>
        <p className="terms-text">
          Welcome to Ring Match! We provide a platform for users to find potential matches through suggestions and recommendations. By using our platform, you acknowledge that any actions taken outside of the app, including dates or interactions with other users, are entirely at your own risk. We are not responsible for any external activities or the sharing of personal data. Please read these Terms and Conditions carefully before using our services.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">1. Premium Features</h2>
        <p className="terms-text">
          Ring Match offers premium features including unlimited friends, messaging, profile customization, and more. Premium subscriptions are paid using Pi cryptocurrency only. We do not accept any other form of payment. All premium subscriptions are non-refundable, and users can view their payment history. Users are responsible for making payments through the official Pi Network SDK.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">2. User Responsibilities</h2>
        <p className="terms-text">
          Users are solely responsible for their behavior on the platform. This includes interactions, messages, and sharing of any personal information. We do not encourage sharing personal contact information, and any such sharing is at the user's own risk. If you encounter any inappropriate behavior, you can report, block, or complain about users through the platform.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">3. Account and Registration</h2>
        <p className="terms-text">
          To use Ring Match, you must register through Pi Network authentication. We collect your Pi Network UID and username, which cannot be changed after registration. You can edit other details like name, bio, photo, etc., but your Pi Network UID and username are fixed.
        </p>
        <p className="terms-text">
          If you choose to delete your account, all personal data associated with the account will be deleted within one month, after review and validation of any reported violations or spam activities.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">4. Content Ownership and Uploading</h2>
        <p className="terms-text">
          Users own the content they upload to Ring Match, such as photos, bio, and text messages. You can delete or modify your content at any time. However, users are solely responsible for what they upload, and any inappropriate content or violations of the platform's guidelines will be dealt with appropriately. We do not currently support multimedia content, but any future additions will be managed in accordance with these Terms.
        </p>
        <p className="terms-text">
          We do not track messages or content unless reports are made. In cases of reported harassment, hate speech, or other violations, we may block or suspend accounts, and legal action can be taken if necessary.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">5. Privacy and Data Use</h2>
        <p className="terms-text">
          For information about how we collect, use, and protect your personal data, please refer to our Privacy Policy. We use Google Firestore for data storage, and we take all necessary measures to ensure the security of your information. We will not share your data unless required by law or for investigative purposes.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">6. Termination of Service</h2>
        <p className="terms-text">
          We reserve the right to suspend or terminate your account if you violate these Terms and Conditions or engage in criminal activity. Accounts may also be suspended in cases of reported harassment or misconduct after a thorough investigation. Any suspension or termination of service will be at our discretion.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">7. Limitation of Liability</h2>
        <p className="terms-text">
          By using Ring Match, you acknowledge that you are using the platform at your own risk. We are not responsible for any damages, including personal harm, financial loss, or legal issues, that result from using the platform, interacting with other users, or engaging in activities outside of the app. We provide the platform to help you find matches, but we are not responsible for the outcome of any interactions.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">8. Dispute Resolution</h2>
        <p className="terms-text">
          Any disputes arising from the use of Ring Match will be resolved through binding arbitration in accordance with the **Indian Arbitration and Conciliation Act, 1996**. The arbitration will take place in Mandvi, India, and the decision of the arbitrator will be final. Users waive the right to trial by jury or participate in a class-action lawsuit. All parties will bear their own costs and expenses associated with arbitration.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">9. Governing Law</h2>
        <p className="terms-text">
          These Terms and Conditions shall be governed by the laws of **India**. Any legal disputes that cannot be resolved through arbitration will be subject to the exclusive jurisdiction of the courts in Mandvi, India.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">10. Changes to Terms</h2>
        <p className="terms-text">
          We reserve the right to update or change these Terms and Conditions at any time. Users will be notified of any significant changes and will be required to accept the new terms before continuing to use the platform.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="terms-subheader">11. Contact Us</h2>
        <p className="terms-text">
          If you have any questions about these Terms and Conditions, please contact us at [dhaval.j.bhatti@gmail.com].
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
