import React, { useState, useEffect, useRef } from 'react';
import { db, storage } from '../../../firebase'; // Import your Firestore and Firebase Storage setup
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Firestore functions
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Firebase Storage functions
import Cropper from 'react-cropper'; // Import Cropper component
import 'cropperjs/dist/cropper.css'; // Import Cropper CSS
import './css/editprofile.style.css'; // Import styles

const EditProfile = () => {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [bio, setBio] = useState('');
  const [age, setAge] = useState('');
  const [profession, setProfession] = useState('');
  const [dob, setDob] = useState('');
  const [location, setLocation] = useState('');
  const [astroSign, setAstroSign] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [photoURL, setPhotoURL] = useState('https://via.placeholder.com/150'); // Default photo
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // File for photo upload
  const [showCropper, setShowCropper] = useState(false); // Show Cropper
  const [imageUrl, setImageUrl] = useState(null); // Image URL for cropping
  const cropperRef = useRef(null); // Reference for the Cropper instance
  const uid = localStorage.getItem('uid'); // Get user ID from localStorage

  // Fetch user data from Firestore on component mount
  useEffect(() => {
    if (uid) {
      const fetchUserData = async () => {
        try {
          const userRef = doc(db, 'users', uid); // Reference to user's Firestore document
          const docSnapshot = await getDoc(userRef); // Fetch user data

          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setUsername(data.username || '');
            setName(data.name || '');
            setGender(data.gender || '');
            setBio(data.bio || '');
            setAge(data.age || '');
            setProfession(data.profession || '');
            setDob(data.dob || '');
            setLocation(data.location || '');
            setAstroSign(data.astroSign || '');
            setHeight(data.height || '');
            setWeight(data.weight || '');
            setPhotoURL(data.photoURL || 'https://via.placeholder.com/150'); // Set photo URL
          }
        } catch (error) {
          console.error('Error fetching user data: ', error);
        }
      };

      fetchUserData(); // Call function to fetch user data
    }
  }, [uid]);

  // Handle image selection and show cropper
  const handlePhotoChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (!file) {
      console.log('No file selected!');
      return;
    }
    setSelectedFile(file); // Save selected file
    setImageUrl(URL.createObjectURL(file)); // Create URL for preview
    setShowCropper(true); // Show the cropper
  };

  // Handle image cropping
  const handleCrop = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      const croppedData = croppedCanvas.toDataURL();
      setPhotoURL(croppedData); // Update photo URL with cropped image
      setShowCropper(false); // Hide the cropper
    } else {
      console.error("Cropper instance is not available.");
    }
  };

  // Upload the image to Cloudinary
  const handleUpload = async () => {
    if (!photoURL) {
      alert('Please select and crop an image first.');
      return;
    }

    const storageRef = getStorage(); // Get Firebase Storage reference
    const fileRef = ref(storageRef, `profile-pictures/${uid}`); // Reference to store the image with the user's UID

    try {
      // Convert base64 image to a Blob
      const response = await fetch(photoURL);
      const blob = await response.blob();

      // Create a metadata object for the upload
      const metadata = {
        contentType: 'image/jpeg',
      };

      // Upload file to Firebase Storage
      const uploadTask = uploadBytesResumable(fileRef, blob, metadata);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Progress monitoring logic (optional)
        },
        (error) => {
          console.error('Upload error: ', error);
          alert('Error uploading image. Please try again.');
        },
        async () => {
          // Get download URL after successful upload
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setPhotoURL(downloadURL); // Update the profile picture URL with the Firebase Storage URL
        }
      );
    } catch (error) {
      console.error('Error uploading photo:', error);
      alert('Error uploading photo. Please try again.');
    }
  };

  // Save changes to Firestore
  const handleSaveChanges = async () => {
    if (!name || !bio || !age || !profession || !dob || !location || !astroSign || !height || !weight) {
      alert('Please fill in all fields before saving changes.');
      return;
    }

    if (uid) {
      setIsSubmitting(true); // Set submitting state
      const userRef = doc(db, 'users', uid); // Reference to user's Firestore document

      try {
        // Update user's data in Firestore
        await updateDoc(userRef, {
          username,
          name,
          gender,
          bio,
          age,
          profession,
          dob,
          location,
          astroSign,
          height,
          weight,
          photoURL, // Save the Cloudinary photo URL
        });
        alert('Profile updated successfully!');
      } catch (error) {
        console.error('Error updating profile: ', error);
        alert('Failed to save changes. Please try again.');
      } finally {
        setIsSubmitting(false); // Reset submitting state
      }
    }
  };

  return (
    <div className="edit-profile-container">
      <div className="profile-form">
        {/* Profile Picture Section */}
        <div className="profile-picture-section">
          <div className="profile-picture">
            <img src={photoURL} alt="Profile" className="profile-picture-img" />
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
            className="upload-photo-input"
          />
        </div>

        {/* Cropping Modal */}
        {showCropper && (
          <div className="cropper-modal">
            <Cropper
              ref={cropperRef}
              src={imageUrl}
              style={{ height: 400, width: '100%' }}
              aspectRatio={1}
              guides={false}
            />
            <button className="crop-button" onClick={handleCrop}>Crop & Upload</button>
          </div>
        )}

        {/* Editable Profile Information */}
        <div className="profile-info-section">
          <label htmlFor="name" className="input-label">Name</label>
          <input
            id="name"
            className="input-field"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="profile-info-section">
          <label htmlFor="username" className="input-label">Username</label>
          <input
            id="username"
            className="input-field"
            value={username}
            disabled
          />
        </div>

        <div className="profile-info-section">
  <label htmlFor="gender" className="input-label">Gender</label>
  <select
    id="gender"
    className="input-field"
    value={gender} // Assume `gender` is a state variable
    onChange={(e) => setGender(e.target.value)} // Update the gender state when selected
  >
    <option value="">Select Gender</option>
    <option value="Male">Male</option>
    <option value="Female">Female</option>
    <option value="Custom">Custom</option>
    <option value="prefer-not-to-say">Prefer not to say</option>
  </select>
</div>
        

<div className="profile-info-section">
      <label htmlFor="bio" className="input-label">Bio</label>
      <textarea
        id="bio"
        className="input-field"
        value={bio}
        onChange={(e) => setBio(e.target.value)}
        minLength={100}  // Set the minimum length to 100 characters
        placeholder="Tell us about yourself. Minimum 100 characters."
        required // Ensures the bio field is required
      />
      {bio.length < 100 && bio.length > 0 && (
        <p className="error-message">Bio must be at least 100 characters.</p>
      )}
    </div>

        <div className="profile-info-section">
          <label htmlFor="age" className="input-label">Age</label>
          <input
            id="age"
            className="input-field"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
        </div>

        <div className="profile-info-section">
          <label htmlFor="profession" className="input-label">Profession</label>
          <input
            id="profession"
            className="input-field"
            value={profession}
            onChange={(e) => setProfession(e.target.value)}
          />
        </div>

        <div className="profile-info-section">
          <label htmlFor="dob" className="input-label">Date of Birth</label>
          <input
            id="dob"
            className="input-field"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
        </div>

        <div className="profile-info-section">
          <label htmlFor="location" className="input-label">Location</label>
          <input
            id="location"
            className="input-field"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>

        <div className="profile-info-section">
          <label htmlFor="astroSign" className="input-label">Astrological Sign</label>
          <input
            id="astroSign"
            className="input-field"
            value={astroSign}
            onChange={(e) => setAstroSign(e.target.value)}
          />
        </div>

        <div className="profile-info-section">
          <label htmlFor="height" className="input-label">Height</label>
          <input
            id="height"
            className="input-field"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
        </div>

        <div className="profile-info-section">
          <label htmlFor="weight" className="input-label">Weight</label>
          <input
            id="weight"
            className="input-field"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </div>
        {/* Other form fields... */}

        {/* Action Button Section */}
        <div className="profile-actions">
          <button
            className="save-changes-button"
            onClick={handleSaveChanges}
            disabled={isSubmitting} // Disable button while submitting
          >
            {isSubmitting ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
