// Header.js
import React from 'react';
import './css/header.style.css'; // Import the CSS file for header styles

function Header() {
    return (
        <header className="dashboard-header">
            <div className="company-name">Ring Match</div>
            <div className="message-icon">
                <i className="fas fa-comment"></i> {/* Font Awesome for message icon */}
            </div>
        </header>
    );
}

export default Header;
