import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Firebase config import
import { collection, getDocs, doc, getDoc } from 'firebase/firestore'; // Import necessary Firestore functions
import Footer from '../Footer';
import fallbackImage from './ano.jpg';
import './css/notification.style.css'; // Import the CSS file

function NotificationPage() {
  const [friendRequests, setFriendRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFriendRequests = async () => {
      try {
        const uid = localStorage.getItem('uid'); // Get logged-in user UID from localStorage

        if (!uid) {
          setError('User not logged in');
          setLoading(false);
          return;
        }

        // Access the 'friendRequest' sub-collection of the logged-in user
        const friendRequestsRef = collection(db, 'users', uid, 'friendRequest');
        const snapshot = await getDocs(friendRequestsRef);

        if (snapshot.empty) {
          console.log('No friend requests found.');
        } else {
          const requests = [];

          // For each friend request, fetch user details (username, photoURL, etc.)
          for (let docSnap of snapshot.docs) {
            const friendUid = docSnap.id; // Assuming the document ID is the UID of the sender
            const userRef = doc(db, 'users', friendUid);
            const userSnap = await getDoc(userRef);

            if (userSnap.exists()) {
              const userData = userSnap.data();
              requests.push({
                uid: friendUid,
                name: userData.name,
                username: userData.username,
                photoURL: userData.photoURL || fallbackImage, // Use a fallback if photoURL is not present
              });
            }
          }

          console.log('Fetched friend requests:', requests);
          setFriendRequests(requests); // Store the friend requests in the state
        }
      } catch (err) {
        setError('Failed to load friend requests');
        console.error('Error fetching friend requests:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchFriendRequests(); // Run the function to fetch friend requests when the component mounts
  }, []); // Empty dependency array means this runs once after the component mounts

  return (
    <div className="notification-container">
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      {/* Display the friend requests as banners */}
      <div className="notification-list">
        {friendRequests.map((request) => (
          <div key={request.uid} className="notification-item">
            <img
              src={request.photoURL}
              alt={`${request.username}'s profile`}
              className="notification-avatar"
            />
            <div className="notification-details">
              <h4 className="notification-username">{request.username}</h4>
              <p className="notification-name">{request.name}</p>
            </div>
            <button className="notification-button accept">Accept</button>
            <button className="notification-button reject">Reject</button>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default NotificationPage;
