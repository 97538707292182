import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase'; // Assuming you've exported the Firestore instance
import { collection, getDocs, doc, getDoc } from 'firebase/firestore'; // Firestore functions
import { useNavigate } from 'react-router-dom'; // Updated to useNavigate
import './css/friends.style.css'; // Assuming you have a CSS file for styling

function Friends() {
  const [friends, setFriends] = useState([]); // To store the list of friends
  const [loading, setLoading] = useState(true); // To show loading state
  const navigate = useNavigate(); // Use navigate instead of useHistory

  // Retrieve logged-in user ID from localStorage
  const loggedInUserId = localStorage.getItem('uid'); // Assuming 'uid' is stored in localStorage

  useEffect(() => {
    if (!loggedInUserId) return; // If no logged-in user, return early

    const fetchFriends = async () => {
      try {
        // Reference to the logged-in user's "friends" sub-collection
        const friendsRef = collection(db, 'users', loggedInUserId, 'friends');
        const friendsSnapshot = await getDocs(friendsRef);

        if (friendsSnapshot.empty) {
          setFriends([]); // No friends found
        } else {
          // For each friendID, fetch details from the 'users' collection
          const friendsList = await Promise.all(
            friendsSnapshot.docs.map(async (docSnapshot) => {
              const friendID = docSnapshot.id; // Get the friendID
              const friendDocRef = doc(db, 'users', friendID); // Get reference to the friend's document in the 'users' collection
              const friendDocSnapshot = await getDoc(friendDocRef);
              const friendData = friendDocSnapshot.data(); // Get friend's data

              // Return friend data with the friendID
              return {
                id: friendID,
                ...friendData,
              };
            })
          );

          setFriends(friendsList); // Update the state with the friends' list
        }

        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error('Error fetching friends from Firestore:', error);
        setLoading(false);
      }
    };

    fetchFriends(); // Call the function to fetch the friends
  }, [loggedInUserId]); // Dependency on the logged-in user ID

  // Show loading state while fetching data
  if (loading) {
    return <div>Loading your friends...</div>;
  }

  // If no friends, display a message
  if (friends.length === 0) {
    return <div>You have no friends yet.</div>;
  }

  return (
    <div className="friends-page">
  
      {/* Display friends' details */}
      <div className="friends-list">
        {friends.map(friend => (
          <div
            key={friend.id}
            className="friend-banner"
            onClick={() => navigate(`/profile/${friend.id}`)} // Navigate to friend profile
          >
            <div className="friend-banner-content">
              <img
                src={friend.photoURL || 'https://via.placeholder.com/150'}
                alt={`${friend.name}'s profile`}
                className="profile-photo"
              />
              <div className="friend-info">
                <h3>{friend.username}</h3>
                <p>{friend.name}</p>
              </div>
              {/* Golden Sparkling Premium Badge */}
              {friend.premium && (
                <div className="friend-badge">
                  <span className="badge-text">Premium</span> {/* Example badge */}
                </div>
              )}
            </div>
            {/* Static Message button */}
            <button
              className="message-button"
              onClick={(e) => {
                e.stopPropagation(); // Prevent click on the whole banner
                console.log(`Message to ${friend.username}`);
                // Add logic to open message/chat screen/modal
              }}
            >
              Message
            </button>
          </div>
        ))}
      </div>
    </div>
  );
  
  
  
  
  
  
}

export default Friends;
