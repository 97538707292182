import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore'; // Firestore functions
import { db } from '../../../firebase'; // Firestore configuration
import './css/yourprofile.style.css'; // Import styles

function YourProfile() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const [activeTab, setActiveTab] = useState('photos'); // State for managing active tab
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const [selectedImage, setSelectedImage] = useState(''); // State for the selected image URL
  const uid = localStorage.getItem('uid'); // Get user ID from localStorage

  useEffect(() => {
    if (uid) {
      const fetchUserData = async () => {
        try {
          const userRef = doc(db, 'users', uid); // Reference to the user's Firestore document
          const docSnapshot = await getDoc(userRef); // Fetch user data

          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setUserData(data);

            // Check for missing required fields
            if (!data.name || !data.bio || !data.age || !data.profession || !data.photoURL) {
              setShowPopup(true); // Show popup if fields are missing
            }
          } else {
            console.error('User document not found!');
          }
        } catch (error) {
          console.error('Error fetching user data: ', error);
        }
      };

      fetchUserData(); // Fetch user data when the component loads
    }
  }, [uid]);

  const handleTabClick = (tab) => {
    if (!showPopup) {
      setActiveTab(tab);
    }
  };

  const handlePhotoClick = () => {
    setSelectedImage(userData.photoURL || 'https://via.placeholder.com/150'); // Set selected image URL
    setShowModal(true); // Open the modal
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
  };

  if (!userData) {
    return <p>Loading profile...</p>;
  }

  const {
    username,
    name,
    gender,
    bio,
    photoURL,
    age,
    profession,
    dob,
    location,
    astroSign,
    height,
    weight,
    views = 0,
    likes = 0,
    friends = 0,
  } = userData;

  // Redirect to EditProfile if popup is open
  if (showPopup) {
    return (
      <div className="pro-profile-popup-container">
        <div className="pro-profile-popup">
          <h2>Complete Your Profile</h2>
          <p>
            Your profile is incomplete. Please update the required information (Name, Bio, Age, Profession, and Photo).
          </p>
          <button className="pro-edit-profile-button" onClick={() => navigate('/editprofile')}>
            Edit Profile
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>

      {/* Profile Content */}
      <div className="pro-profile-content">
        {/* Profile Photo and Stats */}
        <div className="pro-profile-details">
          {/* Profile Photo */}

          <div className="pro-profile-photo-container" onClick={handlePhotoClick}>
            <img
              src={photoURL || 'https://via.placeholder.com/150'}
              alt="Profile"
              className="pro-profile-photo"
            /><br />
            
          </div>

          {/* Stats */}
          <div className="pro-profile-stats">
            <div className="pro-stat-item">
              <span className="pro-stat-number">{views}</span><br />
              <span className="pro-stat-label">Views</span>
            </div>
            <div className="pro-stat-item">
              <span className="pro-stat-number">{likes}</span><br />
              <span className="pro-stat-label">Likes</span>
            </div>
            <div className="pro-stat-item">
              <span className="pro-stat-number">{friends}</span><br />
              <span className="pro-stat-label">Friends</span>
            </div>
          </div>
        </div>

        {/* Name and Bio */}
        <div className="pro-profile-info">
        <h2>@{username}</h2><br />
          <h2 className="pro-profile-name">
            {name || 'John Doe'} {gender ? `| ${gender}` : '| N/A'}
          </h2>
          <p className="pro-profile-age-profession">
            Age: {age || 'N/A'} | Profession: {profession || 'N/A'}
          </p>
          <p className="pro-profile-bio">
            {bio || 'This is your short bio. You can update it later.'}
          </p>
        </div>

        {/* More Details */}
        <div className="pro-profile-more-details">
          <p><strong>Date of Birth:</strong> {dob || 'N/A'}</p>
          <p><strong>Location:</strong> {location || 'N/A'}</p>
          <p><strong>Astrological Sign:</strong> {astroSign || 'N/A'}</p>
          <p><strong>Height:</strong> {height || 'N/A'} | <strong>Weight:</strong> {weight || 'N/A'}</p>
        </div>

        {/* Action Buttons */}
        <div className="pro-profile-actions">
          <button className="pro-profile-button" onClick={() => navigate('/editprofile')}>Edit Profile</button>
          <button className="pro-profile-button">Share Profile</button>
        </div>

        {/* Tabs for Photos and Videos */}
        <div className="pro-media-tabs">
          <button
            className={`pro-tab-button ${activeTab === 'photos' ? 'active' : ''}`}
            onClick={() => handleTabClick('photos')}
          >
            Your Photos
          </button>
          <button
            className={`pro-tab-button ${activeTab === 'videos' ? 'active' : ''}`}
            onClick={() => handleTabClick('videos')}
          >
            Your Videos
          </button>
        </div>

        {/* Conditional Rendering */}
        <div className="pro-media-content">
          {activeTab === 'photos' ? (
            <p>No photos uploaded yet.</p>
          ) : (
            <p>No videos uploaded yet.</p>
          )}
        </div>
      </div>

      {/* Modal for Viewing Photo */}
      {showModal && (
        <div className="pro-photo-modal">
          <div className="pro-photo-modal-content">
            <img src={selectedImage} alt="Profile" className="pro-photo-modal-image" />
            <button className="pro-close-photo-modal" onClick={handleModalClose}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default YourProfile;
