import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Assuming you've exported the Firestore instance
import { collection, getDocs, setDoc, doc, deleteDoc } from 'firebase/firestore'; // Firestore functions
import Footer from '../Footer';
import './css/search.style.css';

function SearchPage() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(new Set()); // To keep track of users with pending requests
  const [receivedRequests, setReceivedRequests] = useState(new Set()); // To keep track of users with received requests
  const [friends, setFriends] = useState(new Set()); // To keep track of users in the "friends" list

  // Retrieve logged-in user ID from localStorage
  const loggedInUserId = localStorage.getItem('uid'); // Assuming 'uid' is stored in localStorage

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users'); // Reference to the 'users' collection
        const userSnapshot = await getDocs(usersCollection); // Fetch data from Firestore
        const userList = userSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(), // Spread user data from Firestore
        }));

        // Filter out the logged-in user's profile
        const filteredUsers = userList.filter(user => user.id !== loggedInUserId);

        // Reorder the list to ensure 'Dj8907' is always at the top
        const sortedUserList = filteredUsers.sort((a, b) => {
          if (a.username === 'Dj8907') return -1; // Move 'Dj8907' to the top
          if (b.username === 'Dj8907') return 1; // Move 'Dj8907' to the top
          return 0; // No change for other users
        });

        setUsers(sortedUserList); // Update the state with the sorted user list
        setLoading(false); // Set loading to false after fetching users
      } catch (error) {
        console.error('Error fetching users from Firestore:', error);
        setLoading(false);
      }
    };

    fetchUsers(); // Call the fetch function
  }, [loggedInUserId]);

  useEffect(() => {
    const checkRequestsAndFriends = async () => {
      try {
        const sentRequestRef = collection(db, 'users', loggedInUserId, 'sentRequest');
        const sentRequestSnapshot = await getDocs(sentRequestRef);

        const pending = new Set();
        sentRequestSnapshot.forEach(doc => {
          pending.add(doc.id); // Add the user ID to the Set of pending requests
        });
        
        setPendingRequests(pending);

        // Check if there are received requests
        const receivedRequestRef = collection(db, 'users', loggedInUserId, 'friendRequest');
        const receivedRequestSnapshot = await getDocs(receivedRequestRef);

        const received = new Set();
        receivedRequestSnapshot.forEach(doc => {
          received.add(doc.id); // Add the user ID to the Set of received requests
        });

        setReceivedRequests(received);

        // Check the friends list
        const friendsRef = collection(db, 'users', loggedInUserId, 'friends');
        const friendsSnapshot = await getDocs(friendsRef);

        const friendsSet = new Set();
        friendsSnapshot.forEach(doc => {
          friendsSet.add(doc.id); // Add the user ID to the Set of friends
        });

        setFriends(friendsSet);
      } catch (error) {
        console.error('Error fetching requests or friends:', error);
      }
    };

    if (loggedInUserId) {
      checkRequestsAndFriends(); // Run the function to check pending requests and friends
    }
  }, [loggedInUserId]);

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;

    try {
      setLoading(true);
      setSearchResult(null);
      setNotFound(false);

      const usersCollection = collection(db, 'users');
      const allDocs = await getDocs(usersCollection);
      const searchResults = allDocs.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(
          user =>
            user.id !== loggedInUserId && // Exclude the logged-in user
            (user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
              user.name.toLowerCase().includes(searchQuery.toLowerCase()))
        );

      if (searchResults.length > 0) {
        setSearchResult(searchResults[0]); // Show the first result
      } else {
        setNotFound(true); // No user found
      }

      setLoading(false);
    } catch (error) {
      console.error('Error searching user in Firestore:', error);
      setLoading(false);
    }
  };

  const handleConnect = async userId => {
    try {
      if (!loggedInUserId) {
        console.error('No logged-in user found');
        return;
      }

      // Reference to the logged-in user's "sentRequest" sub-collection
      const sentRequestRef = collection(db, 'users', loggedInUserId, 'sentRequest');
      
      // Reference to the target user's "friendRequest" sub-collection
      const friendRequestRef = collection(db, 'users', userId, 'friendRequest');

      // Add the request to the logged-in user's "sentRequest" sub-collection
      await setDoc(doc(sentRequestRef, userId), {
        sentAt: new Date(), // Timestamp when the request was sent
      });

      // Add the request to the target user's "friendRequest" sub-collection
      await setDoc(doc(friendRequestRef, loggedInUserId), {
        receivedAt: new Date(), // Timestamp when the request was received
        fromUserId: loggedInUserId, // ID of the sender
      });

      console.log(`Friend request sent to user with ID: ${userId}`);

      // After sending the request, update the pending state
      setPendingRequests(prev => new Set(prev.add(userId)));
    } catch (error) {
      console.error('Error sending friend request:', error);
    }
  };

  const handleAcceptConnection = async userId => {
    try {
      if (!loggedInUserId) {
        console.error('No logged-in user found');
        return;
      }

      // Move the request to the "friends" sub-collection for both users
      await setDoc(doc(db, 'users', loggedInUserId, 'friends', userId), {
        connectedAt: new Date(), // Timestamp when the users became friends
      });

      await setDoc(doc(db, 'users', userId, 'friends', loggedInUserId), {
        connectedAt: new Date(),
      });

      // Delete the friend request and the sent request
      await deleteDoc(doc(db, 'users', loggedInUserId, 'friendRequest', userId));
      await deleteDoc(doc(db, 'users', userId, 'sentRequest', loggedInUserId));

      // Update the state to reflect the connection
      setFriends(prev => new Set(prev.add(userId)));
      setReceivedRequests(prev => {
        const updated = new Set(prev);
        updated.delete(userId);
        return updated;
      });
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  if (loading) {
    return <div>Loading users...</div>; // Show loading state while fetching
  }

  return (
    <div className="search-page">
      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search by username or name..."
          className="search-input"
        />
        <button onClick={handleSearch} className="search-button">
          Search
        </button>
      </div>

      {/* Search Results */}
      {searchResult && (
        <div className="search-user-item">
          <img
            src={searchResult.photoURL || 'https://via.placeholder.com/150'}
            alt={`${searchResult.name}'s profile`}
            className="search-profile-photo"
          />
          <div className="search-user-info">
            <h3>
              {searchResult.username}
              {searchResult.premium && (
                <span className="search-premium-badge">Premium Account</span>
              )}
            </h3>
            <p>{searchResult.name}</p>
          </div>

          {/* Button logic */}
          {receivedRequests.has(searchResult.id) && !friends.has(searchResult.id) ? (
            <button
              onClick={() => handleAcceptConnection(searchResult.id)}
              className="search-accept-button"
            >
              Accept Connection
            </button>
          ) : (
            <button
              onClick={() => handleConnect(searchResult.id)}
              className={`search-connect-button ${pendingRequests.has(searchResult.id) ? 'search-pending' : ''}`}
              disabled={pendingRequests.has(searchResult.id) || friends.has(searchResult.id)}
            >
              {friends.has(searchResult.id) ? 'Connected' : pendingRequests.has(searchResult.id) ? 'Pending' : 'Connect'}
            </button>
          )}
        </div>
      )}

      {notFound && <div>No user found with that name or username.</div>}

      {/* User List */}
      {!searchResult && !notFound && (
        <div className="search-user-list">
          {users.map(user => (
            <div
              key={user.id}
              className={`search-user-item ${user.username === 'Dj8907' ? 'search-fixed-user' : ''}`}
            >
              <img
                src={user.photoURL || 'https://via.placeholder.com/150'}
                alt={`${user.name}'s profile`}
                className="search-profile-photo"
              />
              <div className="search-user-info">
                <h3>
                  {user.name}
                  <br />
                  {user.premium && (
                    <span className="search-premium-badge">Premium Account</span>
                  )}
                </h3>
                <p> @{user.username}</p>
              </div>

              {/* Button logic */}
              {receivedRequests.has(user.id) && !friends.has(user.id) ? (
                <button
                  onClick={() => handleAcceptConnection(user.id)}
                  className="search-accept-button"
                >
                  Accept Connection
                </button>
              ) : (
                <button
                  onClick={() => handleConnect(user.id)}
                  className={`search-connect-button ${pendingRequests.has(user.id) ? 'search-pending' : ''}`}
                  disabled={pendingRequests.has(user.id) || friends.has(user.id)}
                >
                  {friends.has(user.id) ? 'Connected' : pendingRequests.has(user.id) ? 'Pending' : 'Connect'}
                </button>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default SearchPage;
