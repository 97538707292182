import React from 'react';
import './css/dashboard.style.css';
import Header from './Header'; // Import Header component
import Footer from './Footer'; // Import Footer component

function Dashboard() {
    return (
        <div className="dashboard">
            {/* Header */}
            <Header />

            {/* Main Content */}
            <div className="dashboard-content">
                <h1>Welcome to the Dashboard!</h1>
                <p>You're successfully logged in. Explore the platform.</p>
                <button>Explore Now</button>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default Dashboard;
