// MessagePage.js
import React from 'react';

function MessagePage() {
  return (
    <div>
      <h1>Messages</h1>
      <p>This is the Message Page where you can view and send messages.</p>
      {/* Add message content or chat interface here */}
    </div>
  );
}

export default MessagePage;
