// AddPage.js
import React from 'react';

function AddPage() {
  return (
    <div>
      <h1>Add New Content</h1>
      <p>This is the Add Page where you can add new items.</p>
      {/* Add your content or forms here */}
    </div>
  );
}

export default AddPage;
